import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import { calculateTimeLeft } from "src/utils";
import apiConfig from "src/ApiConfig/ApiConfig";
import { useWeb3React } from "@web3-react/core";
import { injected } from "src/connectors";
// import apiconfigs from "src/ApiConfig/ApiConfig";
import {
  contractAddress,
  NetworkContextName,
  addNetworkHandler,
  ACTIVE_NETWORK,
  getNetworkDetails,
} from "src/constants";
export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("token", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("creatturAccessToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

function checkLogin() {
  const accessToken = window.sessionStorage.getItem("token");
  return accessToken ? true : false;
}

export default function AuthProvider(props) {
  const { activate, account, chainId, deactivate } = useWeb3React();

  const [isLogin, setIsLogin] = useState(checkLogin());
  // const [userData] = useState({});
  const [userData, setUserData] = useState({});
  const [loader, setLoader] = useState(false);
  const [kycData, setKycData] = useState([]);
  const [cmcResults, setcmcResults] = useState([]);
  const [endTime, setEndtime] = useState();
  const [notificationData, setGetNotificationData] = useState([]);
  const [isLoadingNotifications, setIsLoadingNotifications] = useState(false);
  const [timeLeft, setTimeLeft] = useState();

  useEffect(() => {
    if (endTime) {
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft(endTime));
      }, 1000);
      return () => clearTimeout(timer);
    }
  });

  const GetNotificationHandler = async () => {
    setIsLoadingNotifications(true);
    try {
      const res = await axios({
        method: "GET",
        url: apiConfig.GetNotification,
        headers: {
          authorization: `Bearer ${window.sessionStorage.getItem("token")}`,
        },
        params: {
          role: "USER",
        },
      });
      if (res.status === 200) {
        setGetNotificationData(res.data.data);
        setIsLoadingNotifications(false);
        console.log("res.data.data", res.data.data);
      }
      console.log("res***", res);
    } catch (err) {
      console.log(err);
      setIsLoadingNotifications(false);
    }
  };

  useEffect(() => {
    GetNotificationHandler();
  }, []);

  let data = {
    userLoggedIn: isLogin,
    userData,
    kycData,
    setEndtime,
    setTimeLeft,
    loader,
    timeLeft,
    endTime,
    cmcResults,
    notificationData,
    isLoadingNotifications,
    getProfileHandler: () => getProfileHandler(),
    GetNotificationHandler: () => GetNotificationHandler(),
    userLogIn: (type, data) => {
      setSession(data);
      setIsLogin(type);
    },
    checkLogin: () => checkLogin(),
    connectWallet: () => {
      activate(injected, undefined, true).catch((error) => {
        if (error) {
          activate(injected);
        }
      });
    },
  };

  const getMarketCurrentPriceHandler = async () => {
    try {
      const res = await axios({
        method: "GET",
        // url: marketCurrentAPI,
        url: apiConfig.cmcdata,
      });
      // console.log("xCoin----", xCoin);
      if (res.status === 200) {
        let jsondata = JSON.parse(res.data.data);
        setcmcResults(jsondata.data);
        //   const searchForCurrency = jsondata?.data?.find((data) => {
        //     return data?.symbol?.toLowerCase() === xCoin?.toLowerCase();
        //   });
        //   console.log("searchForCurrency", searchForCurrency);
        //   setCurrentINRPriceInUSD(searchForCurrency?.quote?.USD?.price);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getProfileHandler = async () => {
    try {
      setLoader(true);
      const res = await axios.get(apiConfig.myAccount, {
        headers: {
          authorization: `Bearer ${window.sessionStorage.getItem("token")}`,
        },
      });
      if (res.data.status === 200) {
        // setKycData(res?.data?.data?.kyc?.document?.reverse()[0]);
        setUserData(res?.data?.data);
        setLoader(false);
      }
    } catch (error) {
      console.log("ERROR", error);
      setLoader(false);
    }
  };

  //Switch Network

  useEffect(() => {
    if (account && chainId) {
      if (chainId != ACTIVE_NETWORK) {
        window.scrollTo(0, 0);
        if (window.ethereum) {
          swichNetworkHandler();
        }
      }
    }
  }, [chainId, account]);

  const swichNetworkHandler = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x" + ACTIVE_NETWORK.toString(16) }],
      });
    } catch (error) {
      console.log("ERROR", error);
      if (error.code === 4902) {
        addNetworkHandler();
      }
    }
  };

  useEffect(() => {
    getMarketCurrentPriceHandler();
    if (window.sessionStorage.getItem("token")) {
      getProfileHandler();
    }
  }, []);

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
