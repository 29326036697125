export default {
  h1: {
    fontWeight: 700,
    fontSize: 40,
  },
  h2: {
    fontWeight: 700,
    fontSize: 26,

    "@media (max-width:991px)": {
      fontSize: 20,
    },
  },
  h3: {
    fontWeight: 400,
    fontSize: 22,
  },
  h4: {
    fontWeight: 400,
    fontSize: 20,
  },
  h5: {
    fontWeight: 300,
    fontSize: 16,
  },
  h6: {
    fontWeight: 400,
    fontSize: 14,

    "@media (max-width:767px)": {
      fontSize: "14px",
    },
  },
  overline: {
    fontWeight: 500,
  },
  button: {
    textTransform: "capitalize",
    borderRadius: 27,
  },
  body1: {
    fontSize: 12,
    fontWeight: 400,
  },
  body2: {
    fontWeight: 400,
    fontSize: 16,

    letterSpacing: "0",
    "@media (max-width:767px)": {
      fontSize: "14px",
    },
  },
};
