import _ from "lodash";
import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import { softShadows, strongShadows } from "./shadows";
import typography from "./typography";

const baseOptions = {
  direction: "ltr",
  typography,
  overrides: {
    MuiListItem: {
      gutters: {
        paddingLeft: "16px",
        paddingRight: "16px",
      },
    },
    MuiSlider: {
      mark: {
        width: 5,
        height: 5,
        borderRadius: "50%",
        border: "2px solid #fff",
        top: 9,
        backgroundColor: "#222",
        marginLeft: "-2px",
      },
      markActive: {
        width: 5,
        height: 5,
        borderRadius: "50%",
        border: "2px solid #EAB73B",
        top: 9,
        backgroundColor: "#EAB73B",
        marginLeft: "-2px",
      },
    },

    MuiList: {
      padding: {
        paddingTop: "0px",
        paddingBottom: "0px",
      },
    },

    MuiButton: {
      root: { color: "#fff" },
      containedSecondary: {
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "100px",
        color: "#fff",
        fontSize: "14px",
        fontWeight: "400",
        backgroundColor: "#202020",
        padding: "13px 48px",
        "&:hover": {
          color: "#000",
          backgroundColor: "#EAB73B",
        },
      },

      textSizeSmall: {
        // padding:"0px",
        minWidth: "auto",
        "&:hover": {
          color: "#EAB73B",
          backgroundColor: "transparent",
        },
      },
      textSecondary: {
        "&:hover": {
          color: "#222 !important",
          backgroundColor: "transparent",
        },
      },
      containedPrimary: {
        backgroundColor: "#EAB73B",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "100px",
        color: "#000000",
        fontSize: "18px",
        fontWeight: "500",
        padding: "10px 39px",
        "&:hover": {
          backgroundColor: "#EAB73B",
          color: "#000",
        },
      },

      contained: {
        borderRadius: "5px",
        color: "#000",
        fontSize: "18px",
        fontWeight: "500",
        padding: "5px 19px",
        "&:hover": {
          backgroundColor: "#EAB73B",

          color: "#000",
        },
        "&.Mui-disabled": {
          backgroundColor: "#EAB73B",
        },
      },
      outlinedPrimary: {
        borderRadius: "100px",
        color: "#FFFFFF",
        fontSize: "14px",

        fontWeight: 500,
        padding: "5px 26px",
        border: "1px solid #EAB73B",
        "&:hover": {
          backgroundColor: "#EAB73B",
          color: "#000",
        },
      },
      outlinedSizeSmall: {
        padding: "5px 23px",
        fontSize: "14px",
      },
      containedSizeSmall: {
        padding: "6px 23px",
        fontSize: "14px",
        fontWeight: "500",
      },
      containedSizeLarge: {
        padding: "9px 48px",
        fontSize: "18px",
        fontWeight: "600",
      },
    },
    MuiRadio: {
      root: {
        color: "#C0BBBB",
      },
      colorSecondary: {
        "&$checked": {
          color: "#EAB73B",
        },
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: "1px solid #2B2C3B",
        fontSize: "14px",
        fontWeight: "300",
      },
      head: {
        color: "#AAAAAA",
        fontWeight: "500 !important",
      },
    },
    MuiSelect: {
      outlined: {
        "&.MuiSelect-outlined": { fontSize: "13px" },
      },
    },
    MuiStep: {
      alternativeLabel: { marginBottom: "65px" },
    },
    MuiStepLabel: {
      root: {
        "&.MuiStepLabel-alternativeLabel": { flexDirection: "row" },
      },
      label: {
        "&.MuiStepLabel-alternativeLabel": {
          marginTop: "0px",
          marginLeft: "10px",
          fontSize: "14px",
          textAlign: "left",
          whiteSpace: "pre",
        },
        "&.MuiStepLabel-completed": {
          color: "#EAB73B",
        },
        "&.MuiStepLabel-active": {
          color: "#EAB73B",
        },
      },
    },

    MuiStepConnector: {
      lineVertical: { minHeight: "48px" },
      alternativeLabel: {
        left: "2px",
        right: "auto",
        position: "absolute",
      },
    },
    MuiOutlinedInput: {
      input: {
        borderRadius: "10px",
        "&:-webkit-autofill": {
          "-webkit-background-clip": "text !important",
          // transitionDelay: "9999s",
          "caret-color": "transparent",
          "-webkit-box-shadow": "0 0 0 100px transparent inset",
          "-webkit-text-fill-color": "#ccc",
        },
        "&:-internal-autofill-selected": {
          color: "#fff",
        },
      },
      inputMarginDense: {
        // paddingTop: "21.5px",
        // paddingBottom: "20.5px",
        paddingTop: "14.5px",
        paddingBottom: "14.5px",
      },
      notchedOutline: {
        borderColor: "transparent",
      },
      adornedEnd: {
        paddingRight: "23px",
        paddingLeft: "10px",
      },
      root: {
        borderRadius: "10px",
        "&.Mui-focused": {
          "& .MuiOutlinedInput-notchedOutline": { borderColor: "#EAB73B" },
        },
        "&:hover": {
          "& .MuiOutlinedInput-notchedOutline": { borderColor: "#EAB73B" },
        },
        "&:focus": {
          "& .MuiOutlinedInput-notchedOutline": { borderColor: "#EAB73B" },
        },
      },
    },
    // MuiPaper: {
    //   root: {
    //     backgroundColor: "#111010",
    //   },
    // },
    MuiIconButton: {
      root: {
        color: "#777676",
      },
      edgeEnd: {
        marginRight: "0px",
      },
    },
  },
};

const themesOptions = [
  {
    name: "LIGHT",
    overrides: {
      MuiFilledInput: {
        root: { backgroundColor: " #f8f7f7", color: "#000" },
      },
      MuiOutlinedInput: {
        input: {
          borderRadius: "10px",
          "&:-webkit-autofill": {
            "-webkit-background-clip": "text !important",
            // transitionDelay: "9999s",
            "caret-color": "transparent",
            "-webkit-box-shadow": "0 0 0 100px transparent inset",
            "-webkit-text-fill-color": "#000",
          },
          "&:-internal-autofill-selected": {
            color: "#fff",
          },
        },
      },
      MuiInputBase: {
        root: {
          backgroundColor: " #f8f7f7",
          color: "#000",
        },
        input: {
          fontSize: "16px",
          fontWeight: "400",
          "&::placeholder": {
            opacity: 1,
            color: "#a1a1a1",
          },
        },
      },
    },
    typography: {
      fontFamily: "'Poppins', sans-serif",
    },
    palette: {
      type: "light",
      action: {
        active: colors.blueGrey[600],
      },
      background: {
        default: "#fff",
        white: "#000",
        light: "#C8FF00",
        blue: "#dfdfdf",
        dark: "#f5f5f5",
        paper: "#cfcccc",
        yellow: "#cc9c28",
        darkgrey: "#f8f7f7",
        wrapper: "#f5f5f5",
        black: "#fff",
        tabbed: "#cfcccc",
      },
      primary: {
        main: "#000000",
        blueMain: "#5D29FF",
        versa: "#fff",
        white: "#fff",
        dull: "#474444",
        recommended: "#000",
      },
      secondary: {
        main: "#000",
      },
      white: {
        main: "#000",
        dullWhite: "#ececec",
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600],
        white: colors.blueGrey[600],
      },
    },
    shadows: softShadows,
  },
  {
    name: "DARK",
    overrides: {
      MuiOutlinedInput: {
        input: {
          borderRadius: "10px",
          "&:-webkit-autofill": {
            "-webkit-background-clip": "text !important",
            // transitionDelay: "9999s",
            "caret-color": "transparent",
            "-webkit-box-shadow": "0 0 0 100px transparent inset",
            "-webkit-text-fill-color": "#fff",
          },
          "&:-internal-autofill-selected": {
            color: "#fff",
          },
        },
      },
      MuiInputBase: {
        root: {
          backgroundColor: " #1E1E1E",
          color: "#fff",
        },
        input: {
          fontSize: "16px",
          fontWeight: "400",
          "&::placeholder": {
            opacity: 1,
            color: "#a1a1a1",
          },
        },
        multiline: {
          backgroundColor: "#1E1E1E",
          border: "none",
          borderRadius: "10px",
        },
      },
    },
    typography: {
      fontFamily: "'Poppins', sans-serif",
    },
    palette: {
      type: "dark",
      action: {
        active: "rgba(255, 255, 255, 0.54)",
        hover: "rgba(255, 255, 255, 0.04)",
        selected: "rgba(255, 255, 255, 0.08)",
        disabled: "rgba(255, 255, 255, 0.26)",
        disabledBackground: "rgba(255, 255, 255, 0.12)",
        focus: "rgba(255, 255, 255, 0.12)",
      },
      background: {
        default: "#141516",
        white: "#fff",
        light: "#C8FF00",
        blue: "#141516",
        dark: "#222",
        paper: "#111010",
        yellow: "#EAB73B",
        black: "#000",
        wrapper: "#000",
        greyWhite: "#9A9A9A",
        darkgrey: "#1E1E1E",
        btnbg: "#2E2D2D",
        tabbed: "#1C1C1C",
      },
      primary: {
        main: "#fff",
        versa: "#222",
        white: "#fff",
        dull: "#939393",
        recommended: "#9A9A9A",
      },
      secondary: {
        main: "#000",
      },
      white: {
        main: "#000",
        dullWhite: "#383636",
      },
      text: {
        primary: "#fff",
        secondary: "#adb0bb",
      },
    },
    shadows: strongShadows,
  },
];

export const createTheme = (config = {}) => {
  let themeOptions = themesOptions.find((theme) => theme.name === config.theme);

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    [themeOptions] = themesOptions;
  }

  let theme = createMuiTheme(
    _.merge({}, baseOptions, themeOptions, { direction: config.direction })
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
