// const url = "https://java-cryptostaking.mobiloitte.com";

const url = "https://java.ebulls.in";
const user = `${url}/account`;
const auth = `${url}/auth`;
const account = `${url}/account`;
const wallet = `${url}/wallet`;
const notification = `${url}/notification`;
const order = `${url}`;
const staticData = `${url}/static`;
const Apiconfigs = {
  userLogin: `${auth}`,

  //USERr
  userSignUp: `${user}/signup`,
  verifyUser: `${user}/verify-user`,
  profileUpdate: `${user}/profile-update`,
  uploadFile: `${user}/upload-file`,
  verifyEmailcode: `${user}/verify-Email-code`,
  verifyUserphone: `${user}/verify-phone-Sms-code`,
  verifyOtp: `${user}/verify-sms-code-mobile-app`,
  forgotPassword: `${user}/forget-password-mobile-app`,
  resetPassword: `${user}/reset-password-mobile-app`,
  changePassword: `${user}/change-password`,
  savekycdetails: `${user}/save-kyc-details`,
  userBankAccount: `${user}/add-user-bank-account`,
  accountList: `${user}/get-user-account-list`,
  // userUpiAccount: `${user}/add-Upi-details`,
  userUpiAccount: `${user}/add-Upi-details-User`,
  // upiList: `${user}/upi-List-admin`,
  upiList: `${user}/User-Upi-List`,
  sendphoneNocode: `${user}/send-phoneNo-code`,
  sendSmsOtp: `${user}/send-sms-code`,
  VerifySmsOtp: `${user}/verify-sms-code`,
  LoginAccountActivity: `${user}/get-user-login-details`,
  SendEmailOtp: `${user}/resend-verify-otp`,
  VerifyEmailOtp: `${user}/verify-Email-Sms-code`,
  emailsmsdisable: `${user}/email-sms-auth-disable`,
  DisableSms: `${user}/sms-auth-disable`,
  SendGoogleOtp: `${user}/google-auth`,
  VerifyGoogleOtp: `${user}/verify-google-code`,
  DisableGoogle: `${user}/twoFa-disable`,

  //Auth
  verifyEmailcodeauth: `${auth}/verify-Email-code`,
  sendsmscodeauth: `${auth}/send-sms-code`,
  verifysmsauth: `${auth}/verify-sms`,
  verifygoogleauth: `${auth}/verify-google`,

  //Account
  myAccount: `${account}/my-account`,
  resendverifyemail: `${account}/resend-verify-email`,
  verifysmscodemobileapp: `${account}/verify-sms-code-mobile-app`,
  ResetPasswordPhoneNMobile: `${account}/Reset-Password-PhoneNo-Mobile-App`,
  SeurityHandle: `${account}/sessionList`,
  cmcdata: `${account}/cmc-data`,
  cmcgraph: `${account}/ohlc-data`,

  //wallet
  coinlist: `${wallet}/coin/get-coin-list`,
  getalluserbalanceandcoinlist: `${wallet}/wallet/get-all-user-balance-and-coinlist`,
  getdeposits: `${wallet}/wallet/get-deposits`,
  getNetworkdeposit: `${wallet}/wallet/get-deposits-for-erc-bep`,
  getWithdrawDeposit: `${wallet}/wallet/withdraw-erc-BEP`,
  withdraw: `${wallet}/wallet/withdraw`,
  AddressWallet: `${wallet}/wallet/get-address`,
  Availablefund: `${wallet}/wallet/total-balance-subtracted-from-total-blocked-balance-for-user`,
  investedamount: `${wallet}/wallet/total-total-blocked-balance-for-user`,
  // currentamount: `${wallet}/wallet/total-balance-for-user`,
  inramount: `${wallet}/wallet/INR-Amount`,
  percentageamount: `${wallet}/wallet/percentage-for-user`,
  addfixeddeposit: `${wallet}/add-fixed-deposit-vauld`,
  // getfixeddepositlist: `${wallet}/get-fixed-deposit-list`,
  getfixeddepositlist: `${wallet}/get-Fixed-Deposit-Details-by-pagination`,
  // DepositHistoryTransaction: `${wallet}/wallet/filter-get-depositelist`,
  DepositHistoryTransaction: `${wallet}/get-all-transaction-history-USER`,
  WithdrawHistoryTransaction: `${wallet}/wallet/filter-get-withdrawlist`,
  WithdrawApprove: `${wallet}/wallet/approve-withdraw`,
  ApproveNetworkWithdraw: `${wallet}/wallet/approve-withdraw-Bep-Erc`,
  getBalance: `${wallet}/wallet/get-balance`,
  Internaltransfer: `${wallet}/wallet/transfer-internal-amount`,
  TransferHistory: `${wallet}/wallet/get-transfer-internal-for-user`,
  // SearchCoin: `${wallet}/coin/get-coin-details`,
  SearchCoin: `${wallet}/coin/coin-list-search-filter`,
  getcoinPairlist: `${wallet}/coin/get-coinPair-list`,
  getpricebtc: `${wallet}/wallet/get-price-btc`,
  getpriceeth: `${wallet}/wallet/get-price-eth`,
  getpriceusdt: `${wallet}/wallet/get-price-usdt`,
  getpriceinr: `${wallet}/wallet/get-price-inr`,
  setmyveeprice: `${wallet}/coin/set-myvee-price`,
  percentagevalue: `${wallet}/wallet/percentage-for-user`,

  //notification
  Readnotification: `${notification}/read-notification`,
  GetNotification: `${notification}/get-notification-data`,
  deletenotification: `${notification}/delete-notification`,

  //order
  placeOrder: `${order}/order-service-`,
  // placeOrder: `${order}/place-order`,
  myactiveorders: `${order}/order-service-`,
  myOrderHistory: `${order}/order-service-`,
  tradeHistory: `${order}/order-service-`,
  // myactiveorders: `${order}/my-active-orders`,
  // myOrderHistory: `${order}/my-order-history`,
  // tradeHistory: `${order}/trade-history`,

  //likeDislikeCoin
  likeDislike: `${wallet}/coin/set-coinPair-favourite-unfavourite-true`,
  likeList: `${wallet}/coin/get-coin-list-by-favourite`,

  //static
  FaqList: `${staticData}/get-faq-list`,
  StaticDatalist: `${staticData}/get-static-page-data`, // StaticDatalist
  StaticDatalist: `${staticData}/get-static-page-data`,
  subscribeNow: `${staticData}/post-news-letter-to-user`,
  Announcement: `${staticData}/get-all-banner-for-website`,
  submitticket: `${staticData}/submit-support-ticket`,
};

export default Apiconfigs;
