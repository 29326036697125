import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";
import DashboardLayout from "src/layouts/DashboardLayout";
import LoginLayout from "src/layouts/LoginLayout";
export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home")),
  },
  {
    exact: true,
    path: "/exchange",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/BitcoinGraph/Bitcoin")),
  },
  {
    exact: true,
    path: "/market",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Market")),
  },
  {
    exact: true,
    path: "/login",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/Login")),
  },
  {
    exact: true,
    path: "/register",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/Signup")),
  },
  {
    exact: true,
    path: "/coin-approve",
    component: lazy(() =>
      import("src/views/pages/Dashboard/Withdraw/CoinApprove")
    ),
  },
  {
    exact: true,
    path: "/token-approve",
    component: lazy(() =>
      import("src/views/pages/Dashboard/Withdraw/TokenApprove")
    ),
  },
  {
    exact: true,
    path: "/forgot-password",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/Forgotpassword")),
  },
  {
    exact: true,
    path: "/forogotsecurity-verify",
    layout: LoginLayout,
    component: lazy(() =>
      import("src/views/auth/Forgotpassword/ForgotSecurityVry")
    ),
  },
  {
    exact: true,
    path: "/forogotsecurity-phone",
    layout: LoginLayout,
    component: lazy(() =>
      import("src/views/auth/Forgotpassword/ForgotSecurityphone")
    ),
  },
  {
    exact: true,
    path: "/reset-password",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/ResetPassword")),
  },
  {
    exact: true,
    path: "/succesful-reset",
    // layout: HomeLayout,
    component: lazy(() => import("src/views/auth/Successful")),
  },
  {
    exact: true,
    path: "/security",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/Security")),
  },
  {
    exact: true,
    path: "/phone-security",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/Security/PhoneSecurity")),
  },
  {
    exact: true,
    path: "/dashboard",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/index")),
  },
  {
    exact: true,
    path: "/edit-profile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Profile/index")),
  },
  {
    exact: true,
    path: "/profile-details",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Profile/ProfileDetails")),
  },
  {
    exact: true,
    path: "/kyc",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/KYC/index")),
  },
  {
    exact: true,
    path: "/account-security",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Security/index")),
  },
  {
    exact: true,
    path: "/staking",
    layout: HomeLayout,

    component: lazy(() => import("src/views/pages/Stacking/index")),
  },
  {
    exact: true,
    path: "/staking-details",
    layout: HomeLayout,

    component: lazy(() => import("src/views/pages/Stacking/StackingDetails")),
  },

  {
    exact: true,
    path: "/staking-carddetails",
    layout: HomeLayout,

    component: lazy(() => import("src/views/pages/Stacking/StackCardDetails")),
  },
  {
    exact: true,
    path: "/staking-history",
    layout: HomeLayout,

    component: lazy(() => import("src/views/pages/Stacking/StackingHistory")),
  },
  {
    exact: true,
    path: "/banking",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Banking/index")),
  },
  {
    exact: true,
    path: "/Privacy",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/StaticContent/Privacy")),
  },
  {
    exact: true,
    path: "/about-us",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/StaticContent/AboutUs")),
  },
  {
    exact: true,
    path: "/term-condition",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/StaticContent/TermCondition")
    ),
  },
  {
    exact: true,
    path: "/faq",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Faq/index")),
  },
  {
    exact: true,
    path: "/notification",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/StaticContent/Notification")),
  },
  {
    exact: true,
    path: "/e-commerce",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Ecommerce")),
  },
  {
    exact: true,
    path: "/context-support",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/StaticContent/ContextSupport")
    ),
  },
  {
    exact: true,
    path: "/delisting-policy",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/StaticContent/DelistingPolicy")
    ),
  },
  {
    exact: true,
    path: "/Fee",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/StaticContent/Fee")),
  },
  {
    exact: true,
    path: "/mlm-policy",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/StaticContent/MLMpolicy")),
  },
  {
    exact: true,
    path: "/referal-reward",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/StaticContent/ReferalReward")
    ),
  },
  {
    exact: true,
    path: "/refund-policy",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/StaticContent/RefundPolicy")),
  },
  {
    exact: true,
    path: "/Support",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/StaticContent/Support")),
  },
  {
    exact: true,
    path: "/user-coin",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/StaticContent/UseCoin")),
  },
  {
    exact: true,
    path: "/user-agreement",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/StaticContent/UserAgreement")
    ),
  },

  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },

  {
    component: () => <Redirect to="/404" />,
  },
];
