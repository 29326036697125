import React, { useEffect, useContext, useState } from "react";
import {
  Box,
  Typography,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Button,
  makeStyles,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import Apiconfigs from "src/ApiConfig/ApiConfig";
import { IoIosMail } from "react-icons/io";
import { AuthContext } from "src/context/Auth";
import DataNotFound from "./DataNotFound";
const useStyles = makeStyles((theme) => ({
  NotificationBox: {
    background: theme.palette.background.dark,
    "& .MuiListItemAvatar-root": {
      minWidth: "36px",
      "& svg": {
        color: theme.palette.primary.recommended,
        fontSize: "25px",
        marginTop: "4px",
      },
    },
    "& .MuiListItem-root": {
      padding: "0px",
      marginTop: "15px",
      alignItems: "flex-start",
      "& p": {
        color: theme.palette.primary.recommended,
        paddingTop: "4px",
      },
      "& h5": {
        color: theme.palette.primary.recommended,
        fontSize: "10px",
        paddingTop: "4px",
      },
    },
  },
  notificationCount: {
    background: theme.palette.background.paper,
    borderRadius: "7px",
    padding: "4px",
    "& p": {
      color: theme.palette.primary.recommended,
      paddingLeft: "4px",
    },
    "& button": {
      fontSize: "14px",
    },
    "& button.yellow": {
      color: theme.palette.background.yellow,
    },
  },
}));

function Notifications() {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const [notification, setNotification] = useState([]);
  const [lodingdata, setLoadingdata] = useState(false);
  const [deletesms, setDeletesms] = useState("");
  useEffect(() => {
    if (auth?.notificationData) {
      setNotification(auth?.notificationData);
    }
  }, [auth?.notificationData]);

  const deletenotifiation = async () => {
    setLoadingdata(true);
    setDeletesms("Deleting...");
    try {
      const res = await axios({
        method: "DELETE",
        url: Apiconfigs.deletenotification,
        headers: {
          authorization: `Bearer ${window.sessionStorage.getItem("token")}`,
        },
      });
      if (res.data.status === 200) {
        auth.GetNotificationHandler();
        setNotification([]);
        setLoadingdata(false);
        setDeletesms("");
      } else {
        setLoadingdata(false);
        setDeletesms("");
        auth.GetNotificationHandler();
      }
    } catch (err) {
      console.log(err);
      setLoadingdata(false);
      setDeletesms("");
    }
  };

  return (
    <Box className={classes.NotificationBox} p={2}>
      <Box className={`${classes.notificationCount} flexbetween`}>
        <Typography variant="h5" color="primary">
          {notification ? notification?.length : "0"}
        </Typography>
        <Typography variant="body1">Pending notifications</Typography>

        {!lodingdata ? (
          <>
            <Button
              variant="text"
              color="primary"
              className="yellow"
              // onClick={() => setNotification([])}
              onClick={deletenotifiation}
              disabled={lodingdata}
            >
              Clear All
            </Button>
          </>
        ) : (
          <>
            <Button
              variant="text"
              color="primary"
              className="yellow"
              disabled={lodingdata}
            >
              {deletesms}
            </Button>
          </>
        )}

        <Button
          variant="text"
          color="primary"
          component={Link}
          to="/notification"
        >
          View All
        </Button>
      </Box>
      <Box className="notificationContent">
        {notification &&
          notification?.map((data, index) => {
            return (
              <ListItem component={Link} to="/notification">
                <ListItemAvatar>
                  <IoIosMail />
                  <span></span>
                </ListItemAvatar>
                <ListItemText>
                  <Typography
                    variant="h6"
                    color="primary"
                    className=" ellipsys"
                  >
                    {/* {data?.message ? data?.message?.slice(0, 16) : ""}... */}
                    {data?.title ? data?.title?.slice(0, 30) : ""}...
                  </Typography>
                  <Typography variant="body1" className=" ellipsys">
                    {data?.message}
                  </Typography>
                  <Typography variant="h5" className=" ellipsys">
                    {/* 2 days ago */}
                    {data?.createdAt ? moment(data?.createdAt1).fromNow() : ""}
                  </Typography>
                </ListItemText>
              </ListItem>
            );
          })}
        {notification && notification.length === 0 && <DataNotFound />}
      </Box>
    </Box>
  );
}

export default Notifications;
