import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  Grid,
  Box,
  Container,
  Typography,
} from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  bannerBox: {
    padding: "10px 0px 10px 0px",

    position: "relative",
    backgroundColor: theme.palette.background.black,
    background: "url(/images/banner.png)",
    backgroundPosition: "center center !important",
    backgroundSize: "cover !important",
    // padding: " 50px 0px",
    overflow: "hidden",
    zIndex: "1",
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    [theme.breakpoints.down("sm")]: {
      paddingTop: "30px",
      paddingBottom: "40px",
    },
    "& .MuiInputBase-root": {
      backgroundColor: theme.palette.background.white,
      height: 62,
      color: "#000",
    },
    "& .MuiOutlinedInput-input": {
      "&:-webkit-autofill": {
        "-webkit-text-fill-color": "#000",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#b7b4b4",
    },
  },

  content: {
    maxWidth: "410px",
    // width: "100%",
    padding: "40px",
    textAlign: "left",
    background: theme.palette.background.white,
    borderRadius: "20px",

    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      // width: "100%",
      borderRadius: "15px",
      maxWidth: "400px",
      padding: "30px",
    },
    [theme.breakpoints.down("xs")]: {
      // width: "100%",
      borderRadius: "15px",
      maxWidth: "100%",
      padding: "15px",
    },
  },

  // left: {
  //   height: "100vh",
  //   [theme.breakpoints.down("sm")]: {
  //     height: "auto",
  //   },
  //   [theme.breakpoints.down("xs")]: {
  //     // display:"none",
  //   },
  // },
  layoutText: {
    "& h1": {
      fontSize: "58px",
      fontWeight: "900",
      color: theme.palette.primary.white,
      marginBottom: "20px",
      [theme.breakpoints.down("md")]: {
        fontSize: "40px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "30px",
      },
    },
    "& h4": {
      fontWeight: "300",
      color: theme.palette.primary.white,
      marginBottom: "40px",
      fontSize: "20px",
      lineHeight: "30px",
      [theme.breakpoints.down("md")]: {
        fontSize: "18px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "16px",
        lineHeight: "27px",
      },
    },
  },
  box: {
    backgroundColor: "#1690f0",
    //boxShadow: 'rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px',
    [theme.breakpoints.down("sm")]: {
      marginBottom: "50px",
    },
  },
  bannerImg: {
    display: "flex",
    position: "relative",
    // overflow: " hidden",

    "& .shape5": {
      position: "absolute",
      left: " -118px",
      bottom: "-17px",
      width: "20%",
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    "& .shape6": {
      top: "335px",
      width: " 27%",
      right: "75px",
      position: "absolute",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    "& .shape7": {
      top: "331px",
      right: "495px",
      width: "14%",
      position: "absolute",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },

    "& figure": {
      margin: "0",
      width: "100%",
      overflow: "hidden",
      marginBottom: "15px",
      borderRadius: "10px",
      height: "auto",
      "&:hover": {
        "& img": {
          transform: "scale(1.3)",
        },
      },
      "& img": {
        width: "100%",
        height: "100%",
        margin: "0",
        transform: "scale(1.1)",
        transition: "0.5s",
      },
    },
  },
  bannerimgright: {
    display: "flex",
    position: "relative",

    "& .shape1": {
      position: "absolute",
      right: " -143px",
      top: "156px",
      width: "20%",
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    "& .shape2": {
      position: "absolute",
      bottom: "-73px",
      right: "-146px",
      width: " 33%",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  },
}));

const LoginLayout = ({ children }) => {
  const classes = useStyles();
  const location = useLocation();
  const [loginpath, setLoginpath] = useState("");
  console.log("loginpath", loginpath);
  useEffect(() => {
    if (location.search.substring(1, location.search.length)) {
      const id = location.search.substring(1, location.search.length);
      setLoginpath(id);
    }
  }, [location.search]);
  return (
    <Box className={classes.bannerBox}>
      <Container maxWidth="lg">
        <Grid container alignItems="center">
          <Grid item xs={12} sm={12} md={6} className={classes.left}>
            <Box className={classes.bannerImg}>
              <img
                src="loginimages/bitcoin.png"
                className="shape5 moveLeft"
                alt=""
              />
              <img
                src="loginimages/litecoin.png"
                className="shape6 moveTop"
                alt=""
              />
              <img
                src="loginimages/sphere.png"
                className="shape7 moveTop"
                alt=""
              />
              <Link
                to="/"
                style={{ textDecoration: "none", cursor: "pointer" }}
              >
                <Box className={classes.layoutText}>
                  <Typography variant="h1">
                    {" "}
                    {loginpath === "login" ? (
                      <>Welcome Back...</>
                    ) : loginpath === "forgotpassword" ? (
                      <>Forgot Password</>
                    ) : loginpath === "security" ? (
                      <>Security verification</>
                    ) : loginpath === "resetpassword" ? (
                      <>Reset password</>
                    ) : loginpath === "register" ? (
                      <>Welcome to E-bulls</>
                    ) : (
                      <>Welcome Back...</>
                    )}
                  </Typography>
                  <Typography variant="h4">
                    Buy, sell and grow your crypto with Crypto the platform
                    dedicated to every trader at every level.
                  </Typography>
                </Box>
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box className={classes.bannerimgright}>
              <img
                src="loginimages/monero.png"
                className="shape1 moveLeft"
                alt=""
              />
              <img
                src="loginimages/liteiconl.png"
                className="shape2 moveTop"
                alt=""
              />
              <Container style={{ padding: "0px" }}>
                <Box className={classes.signinbox}>
                  <Box className={classes.content}>{children}</Box>
                </Box>
              </Container>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

LoginLayout.propTypes = {
  children: PropTypes.node,
};

export default LoginLayout;
