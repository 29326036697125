import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Grid,
  MenuItem,
  Box,
  Container,
  Popper,
  Grow,
  Paper,
  MenuList,
  Menu,
  Typography,
  Avatar,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import React, { useState, useEffect, useRef, useContext } from "react";
import { NavLink } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import { FaApple, FaGoogle } from "react-icons/fa";

import MenuIcon from "@material-ui/icons/Menu";
import { BsFillBellFill } from "react-icons/bs";
import { AuthContext } from "src/context/Auth";
import { withStyles } from "@material-ui/core/styles";
import { FaMobileAlt } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import Logo from "./../../component/Logo";
import Notifications from "src/component/Notifications";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import SettingsContext from "src/context/SettingsContext";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { IoColorPaletteOutline } from "react-icons/io5";
import { FaUser } from "react-icons/fa";
import { AiOutlineLogout } from "react-icons/ai";
import Scroll from "react-scroll";

const ScrollLink = Scroll.Link;

const headersData = [
  {
    label: "Home",
    href: "/",
  },
  {
    label: "market",
    href: "/market",
  },
  {
    label: "Exchange",
    href: "/exchange",
  },
  {
    label: "Staking",
    href: "/staking",
  },
  // {
  //   label: "Wallet",
  //   href: "/dashboard"
  // },
  // {
  //   label: "Ecommerce",
  //   href: "/fghgf",
  // },
  // {
  //   label: "Trading BOT",
  //   href: "/bulls",
  // },
  // {
  //   label: "Lunchpad",
  //   href: "/bulls",
  // },
];
const useStyles = makeStyles((theme) => ({
  menuButton: {
    fontSize: "12px",
    lineHeight: "21px",
    fontWeight: "500",
    borderRadius: 0,
    minWidth: "auto",
    textTransform: "uppercase",
    color: "#fff",
    padding: "0px 7px",
    letterSpacing: "1px",
    textDecoration: "none",
    marginLeft: "0",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      marginLeft: "0px",
    },
    "@media (max-width: 1200px)": {
      padding: "0px",
      width: "100%",
      display: "block",
      textAlign: "center",
      color: "#fff",
      fontSize: "15px",
      marginBottom: "20px",
    },
    "&.active": {
      color: "#ffc107",
    },
    "&:hover": {
      color: "#ffc107",
    },
  },
  menuButton1: {
    width: "100%",
    textAlign: "center",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    height: "100%",
    "@media (max-width: 990px)": {
      paddingLeft: "75px",
      paddingRight: "20px",
      height: "100%",
    },
  },
  maindrawer: {
    height: "100%",
    background: "#0c0731",
    width: "260px",
  },
  logoDrawer: {
    paddingLeft: "10px",
    width: "218px",
    marginBottom: "30px",
    marginTop: "30px",
  },
  drawerContainer: {
    padding: "20px",
    height: "100%",
    background: "#000",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    textAlign: "center",
  },
  drawericon: {
    color: "#000",
    // position: "absolute",
    top: "0px",
    right: "-10px",
    fontSize: "25px",
  },
  drawericon1: {
    fontSize: "25px",
    right: "-10px",
    paddingRight: "40px",
  },
  logoImg: {
    width: "75px",
    // height: '44.5px',
    margin: " 14px 15px 11px 0px",
    objectFit: "contain",
    "@media (max-width: 500px)": {
      margin: " 11px 1px 3px 0px",
      width: "52px",
    },
  },
  paper1: {
    background: "black",
    color: "white",
  },
  containerHeight: {
    height: "100%",
    paddingLeft: "0px",
    paddingRight: "0px",
  },
  mainHeader: {
    justifyContent: "space-between",
    // padding: "0px",
  },
  search: {
    height: "31px",
    position: "relative",
    color: "#ABABAB",
    borderRadius: "100px",
    backgroundColor: "#E6E6E6",
    border: "1px solid #fff",
    "&:hover": {
      backgroundColor: "#ececec",
      border: "1px solid #300760",
    },
    marginLeft: 20,
    width: "140px",
    maxWidth: "257px",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "180px",
    },
  },
  searchIcon: {
    fontSize: "16px",
    padding: theme.spacing(0, 2),
    color: "#000000",
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    fontSize: "16px",
  },
  wallet: {
    fontSize: "14px",
    fontWeight: "400",
    fontStyle: "normal",
    lineHeight: "21px",
    color: "#fff",
    border: "1px solid #ec0066",
    padding: "0 15px",
    background: "#ec0066",
    borderRadius: "50px",
    height: "31px",
    "&:hover": {
      background: "#fff",
      color: "#ec0066",
    },
    "@media (max-width: 900px)": {
      marginLeft: "12px",
      marginTop: "12px",
    },
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    fontSize: "13px",
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100px",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "100%",
      },
    },
  },
  submenu: {
    borderTop: "3px solid #EAB73B",
    top: "25px !important",
    zIndex: " 1",
  },
  notification: {
    borderTop: "3px solid #EAB73B",
    top: "0px !important",
    width: "340px",
    maxWidth: "100%",
    zIndex: "9999",
    "@media (max-width: 599px)": {
      left: "auto !important",
      right: "0px",
    },
  },
  logoBox: {
    position: "relative",
    marginRight: "30px",
    "@media (max-width: 1200px)": {
      position: "initial",
      top: 0,
      marginRight: "0px",
    },
    "& img": {
      width: "187px",
      display: "block",
      "@media (max-width: 1200px)": {
        width: "auto",
        height: "24px",
        maxWidth: "fit-content",
        display: "inline",
      },
      // "@media (max-width: 990px)": {
      //   width: "280px",
      // },
    },
  },
  mintBtn: {
    height: "100%",
    backgroundColor: "#C8FF00",
    color: "#000",
    fontSize: "14px !important",
    fontWeight: "600",
    textTransform: "uppercase",
    boxShadow: "none",
    borderRadius: 0,
    minHeight: "48px",
    width: "120px",

    "@media (max-width: 990px)": {
      borderRadius: " 40px",
      height: "46px",
      marginTop: "20px",
      width: "calc(100% - 60px)",
      fontSize: "20px !important",
      maxWidth: "300px",
    },
    "&:hover": {
      backgroundColor: "#6442f4",
      color: "#fff",
    },
  },
  socailIcons: {
    paddingRight: "45px",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      paddingRight: "10px",
    },
    "&::after": {
      position: "absolute",
      content: "''",
      height: "25px",
      width: "1px",
      backgroundColor: "#ccc",
      right: "20px",
      top: "50%",
      transform: "translateY(-50%)",
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
  },
  accountBox: {
    "& a": {
      marginLeft: "10px",
      "@media (max-width: 990px)": {
        marginLeft: "0px",
        marginTop: "20px",
        width: "calc(100% - 60px)",
        fontSize: "20px !important",
        maxWidth: "300px",
      },
    },
  },
  headerbar: {
    border: "none",
    borderRadius: "0px !important",
    // boxShadow: "0px 2px 15px rgb(209 209 209)",
    backgroundColor: "transparent",
    opacity: "1",
    "@media (max-width: 990px)": {
      backgroundColor: "#000",
      boxShadow: "none",
    },
  },
  closeButton: {
    position: "absolute",
    width: "15px",
    color: "#6adeea",
    right: "20px",
    top: "10px",
  },
  loginButton: {
    height: "28px",

    width: "28px",
  },
  iconbtnsize: {
    fontSize: "20px",
    marginRight: "10px",
    cursor: "pointer",
    color: "#898989",
  },
  papercolor: {
    background: theme.palette.background.darkgrey,
  },
  menuul: {
    display: "flex",
    listStyle: "none",
    padding: "0",
    alignItems: "center",
    margin: "0",
    height: "50px",
    justifyContent: "space-between",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      display: "block",
      "& .logoDesk": {
        display: "none",
      },
    },

    "& .buttonRound": {
      width: "41px",
      height: "41px",
      borderRadius: "50%",
      color: "#fff",
      marginLeft: "15px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "20px",
      cursor: "pointer",
      border: "2px solid #FFFFFF",
      [theme.breakpoints.down("md")]: {
        marginBottom: "20px",
      },
      [theme.breakpoints.down("xs")]: {
        marginBottom: "20px",
      },
      "&:hover": {
        background: "#fff",
        color: "#000",
        // border: "2px solid #000",
      },
    },
  },
  scrollbutton: {
    display: "flex",
    alignItems: "center",
    "@media (max-width: 1200px)": {
      display: "block",
    },
  },
  barimage: {
    borderTop: "3px solid #EAB73B",
    padding: "20px",
    maxWidth: "162px",
    textAlign: "center",
  },
}));

export default function Header() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClose1 = () => {
    setAnchorEl(null);
  };
  const user = useContext(AuthContext);
  const [loginpath, setLoginpath] = useState();

  useEffect(() => {
    setLoginpath("register");
  });

  //modal
  const handleClick5 = (event) => {
    setAnchorEl5(event.currentTarget);
  };
  const handleClose5 = () => {
    setAnchorEl5(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const {
    menuButton,
    loginButton,
    toolbar,
    scrollbutton,
    inerrMenu,
    btnname,
    logoBox,
    drawerContainer,
    drawericon,
    drawericon1,
    logoDrawer,
    containerHeight,
    mainHeader,
    submenu,
    notification,
    closeButton,
    barimage,
    mintBtn,
    socailIcons,
    menuul,
    menuLeft,
    accountBox,
    wallet,
    headerbar,
    papercolor,
    iconbtnsize,
  } = useStyles();
  const history = useHistory();

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1210
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const [anchorEl5, setAnchorEl5] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const StyledMenu = withStyles({
    paper: {
      marginTop: "10px",
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      {...props}
    />
  ));

  const themeSeeting = useContext(SettingsContext);
  const [open, setOpen] = React.useState(false);
  const [open7, setOpen7] = React.useState(false);
  const [open8, setOpen8] = React.useState(false);
  const [open10, setOpen10] = React.useState(false);
  const changeTheme = (type) => {
    themeSeeting.saveSettings({
      theme: type,
    });
  };
  const [open1, setOpen1] = useState({ community: false, notification: false });
  const anchorRef = { community: useRef(null), notification: useRef(null) };

  const handleToggle = (name) => {
    setOpen1({ ...open1, [name]: !open1[name] });
  };

  const handleClose2 = (event, name) => {
    if (
      anchorRef[name].current &&
      anchorRef[name].current.contains(event.target)
    ) {
      return;
    }

    setOpen1({ ...open1, [name]: false });
  };

  function handleListKeyDown(event, name) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen1({ ...open1, [name]: false });
    }
  }

  const Tokencheck = window.sessionStorage.getItem("token");
  // const Tokennamecheck = window.localStorage.getItem("tokenname");

  const displayDesktop = () => {
    return (
      // <Container maxWidth="lg" className="p-0">
      <Toolbar className={toolbar}>
        {femmecubatorLogo}
        <Grid
          container
          style={{
            paddingLeft: "0px",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Grid item xs={12} align="right">
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                {" "}
                {getMenuButtons()}
                {menuText}
              </Box>

              <Box className="flexBetween">{LoginButtons}</Box>
            </Box>
          </Grid>
        </Grid>
      </Toolbar>
    );
  };

  const LogoutHandler = () => {
    window.sessionStorage.removeItem("token");
    window.localStorage.removeItem("tokenname");
    window.location.href = "/";
  };

  const displayMobile = () => {
    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
      setOpen1(false);
    };
    const Transition = React.forwardRef(function Transition(props, ref) {
      return <Slide direction="up" ref={ref} {...props} />;
    });

    return (
      <Toolbar className={mainHeader}>
        <Dialog fullScreen open={open} onClose={handleClose}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            className={closeButton}
          >
            <CloseIcon />
          </IconButton>

          <Box className={drawerContainer}>
            <Box my={2} style={{ width: "100%" }}>
              {femmecubatorLogo}
            </Box>

            {getDrawerChoices()}
            {menuText}
            {/* <Box mt={1} style={{ width: "100%" }}>
              {LoginButtons}
            </Box> */}
          </Box>
        </Dialog>

        <div> {femmecubatorLogo}</div>

        <Grid container>
          <Grid item xs={12} sm={12} align="right">
            {!token ? (
              <>
                <Button
                  variant="contained"
                  to="/login"
                  component={Link}
                  // onClick={() => setOpen8(true)}
                  color="primary"
                  size="small"
                >
                  Login
                </Button>
              </>
            ) : (
              <>
                <IconButton
                  ref={anchorRef.notification}
                  aria-controls={
                    open1.notification ? "menu-list-grow" : undefined
                  }
                  aria-haspopup="true"
                  onClick={() => handleToggle("notification")}
                >
                  <BsFillBellFill />
                </IconButton>
                <IconButton
                  size="small"
                  ref={anchorRef.community}
                  aria-controls={open1.community ? "menu-list-grow" : undefined}
                  aria-haspopup="true"
                  onClick={() => handleToggle("community")}
                >
                  {" "}
                  <Avatar
                    className={loginButton}
                    src={
                      user.userData?.imageUrl
                        ? user.userData?.imageUrl
                        : "images/user1.png"
                    }
                  />
                </IconButton>
              </>
            )}

            {/* {history.location.pathname !== "/" ? } */}
            <IconButton
              className={
                history.location.pathname === "/dashboard"
                  ? drawericon1
                  : history.location.pathname === "/"
                  ? drawericon
                  : drawericon1
              }
              {...{
                edge: "start",
                color: "inherit",
                "aria-label": "menu",
                "aria-haspopup": "true",
                onClick: handleClickOpen,
              }}
            >
              <MenuIcon
                width="60px"
                height="60px"
                style={{ color: "#EAB73B", fontSize: "30px" }}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
    );
  };

  const getDrawerChoices = () => {
    return headersData.map(({ label, href }) => {
      return (
        <>
          <Button
            {...{
              key: label,
              color: "inherit",
              to: href,
              component: Link,
              className: menuButton,
            }}
          >
            {label}
          </Button>
        </>
      );
    });
  };

  const getMenuButtons = () => {
    return headersData.map(({ label, href }) => {
      return (
        <>
          <NavLink
            activeClassName="active"
            {...{
              key: label,
              color: "inherit",
              to: href,
              // component: Link,
              className: menuButton,
            }}
          >
            {label}
          </NavLink>
        </>
      );
    });
  };

  const menuText = (
    <nav>
      <Box className={scrollbutton}>
        {/* {Tokencheck || Tokennamecheck ? 
          <Box>
            <NavLink to="/dashboard" className={menuButton}>
              Wallet
            </NavLink>
          </Box>
        ) : (
          ""
        )} */}
        {Tokencheck ? (
          <Box>
            <NavLink to="/dashboard" className={menuButton}>
              Wallet
            </NavLink>
          </Box>
        ) : (
          <NavLink to="/login" className={menuButton}>
            Wallet
          </NavLink>
        )}

        <Box>
          <ScrollLink
            className={menuButton}
            smooth={true}
            duration={500}
            to="ecommerce"
            tabIndex="1"
            onClick={() => history.push("/?id=ecommerce")}
          >
            Ecommerce
          </ScrollLink>
        </Box>
        <Box>
          <ScrollLink
            className={menuButton}
            smooth={true}
            duration={500}
            to="trading"
            tabIndex="1"
            onClick={() => history.push("/?id=trading")}
          >
            Trading Bot
          </ScrollLink>
        </Box>

        <Box>
          <ScrollLink
            className={menuButton}
            target="_blank"
            onClick={() => setOpen10(true)}

            // onClick={() => window.open("https://ido-ecomm.mobiloitte.org/")}
            // onClick={() => window.open("https://launchpad.ebulls.in/")}
          >
            Launchpad
          </ScrollLink>
        </Box>
        <Box>
          <ScrollLink
            className={menuButton}
            target="_blank"
            onClick={() => setOpen7(true)}
          >
            NFT Marketplace
          </ScrollLink>
        </Box>
      </Box>
    </nav>
  );

  const femmecubatorLogo = (
    <Box className={logoBox}>
      <Link to="/">
        <Logo className="logoImg" />
      </Link>
    </Box>
  );
  const mobileLogo = (
    <Box className={logoBox}>
      <Link to="/">
        <img src="images/mobile-logo.png" alt="" />
      </Link>
    </Box>
  );

  // const token = window.localStorage.getItem("tokenname");
  const token = window.sessionStorage.getItem("token");

  const LoginButtons = (
    <Box className={accountBox}>
      {!token ? (
        <>
          <Button
            variant="contained"
            to="/login"
            component={Link}
            // onClick={() => setOpen8(true)}
            color="primary"
            size="small"
          >
            Login
          </Button>
          {/* &nbsp; &nbsp;
          <Button
            variant="contained"
            // to="/register"
            // component={Link}
            onClick={() => {
              history.push({
                pathname: "/register",
                search: loginpath,
              });
            }}
            color="primary"
            size="small"
          >
            Sign Up
          </Button> */}
        </>
      ) : (
        <>
          <IconButton onClick={handleClick5}>
            <FaMobileAlt />
          </IconButton>
          <IconButton
            ref={anchorRef.notification}
            aria-controls={open1.notification ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={() => handleToggle("notification")}
          >
            <BsFillBellFill />
          </IconButton>
          <IconButton
            size="small"
            ref={anchorRef.community}
            aria-controls={open1.community ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={() => handleToggle("community")}
          >
            {" "}
            <Avatar
              className={loginButton}
              src={
                user.userData?.imageUrl
                  ? user.userData?.imageUrl
                  : "images/user1.png"
              }
            />
          </IconButton>
        </>
      )}
    </Box>
  );

  return (
    <>
      <AppBar
        position={history.location.pathname !== "/" ? "relative" : "absolute"}
        elevation={0}
        className={headerbar}
      >
        <Container
          maxWidth={history.location.pathname !== "/" ? "lg" : "lg"}
          className={containerHeight}
        >
          {mobileView ? displayMobile() : displayDesktop()}
        </Container>
      </AppBar>

      <Popper
        open={open1.community}
        anchorEl={anchorRef.community.current}
        role={undefined}
        transition
        // disablePortal
        className={submenu}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper className={papercolor}>
              <ClickAwayListener
                onClickAway={(e) => handleClose2(e, "community")}
              >
                <MenuList
                  autoFocusItem={open1}
                  id="menu-list-grow"
                  onKeyDown={(e) => handleListKeyDown(e, "community")}
                >
                  <MenuItem component={Link} to="/dashboard">
                    <IoColorPaletteOutline className={iconbtnsize} />
                    Dashboard
                  </MenuItem>

                  <MenuItem component={Link} to="/profile-details">
                    <FaUser className={iconbtnsize} />
                    Profile
                  </MenuItem>
                  <MenuItem onClick={LogoutHandler}>
                    <AiOutlineLogout className={iconbtnsize} />
                    Logout
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <Popper
        placement="bottom-end"
        open={open1.notification}
        anchorEl={anchorRef.notification.current}
        role={undefined}
        transition
        // disablePortal
        className={notification}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Box>
              <ClickAwayListener
                onClickAway={(e) => handleClose2(e, "notification")}
              >
                <Notifications />
              </ClickAwayListener>
            </Box>
          </Grow>
        )}
      </Popper>

      <StyledMenu
        id="simple-menu"
        anchorEl={anchorEl5}
        keepMounted
        open={Boolean(anchorEl5)}
        onClose={handleClose5}
      >
        {/* <Box p={2} textAlign="center">
          <Typography variant="h4">App</Typography> */}
        {/* <Box textAlign="center" pt={2}>
            <Typography variant="h6">Comming Soon</Typography>
          </Box> */}
        {/* </Box> */}
        <Box className={barimage}>
          <Typography variant="h4">App</Typography>
          <Box>
            {/* <img src="/images/barcode.png" width="100%" /> */}
            <img src="/images/comeapp.png" width="100%" />
          </Box>
          {/* <Typography variant="h6" style={{ color: "#9A9A9A" }}>
            Scan to Download App IOS & Android
          </Typography>
          <Box mt={1}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              startIcon={<FaGoogle style={{ fontSize: "16px" }} />}
              style={{
                whiteSpace: "pre",
                fontSize: "14px",
                fontWeight: "500",
                padding: "7px 35px",
              }}
            >
              App Store
            </Button>
            <Box mt={1}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                startIcon={<FaApple />}
                style={{
                  whiteSpace: "pre",
                  fontSize: "14px",
                  fontWeight: "500",
                  padding: "7px 27px",
                }}
              >
                Google play
              </Button>
            </Box>
          </Box> */}
        </Box>
      </StyledMenu>

      <Dialog
        open={open7}
        fullWidth
        // onClose={() => setOpen7(fals)}
        maxWidth="sm"
        // onClose={handleClosee}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Box>
            <img src="images/dd1.gif" width="100%" />
          </Box>

          <Box display="flex" justifyContent="center" pb={2} pt={2}>
            <Box className={btnname}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setOpen7(false)}
              >
                Close
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      <Box>
        <Dialog
          open={open8}
          fullWidth
          // onClose={() => setOpen7(fals)}
          maxWidth="sm"
          // onClose={handleClosee}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <Box p={2} textAlign="center">
              <Typography variant="h4">
                CONGRATULATIONS !!! EBULLS IS LIVE NOW!!!
              </Typography>
              <Box pt={2}>
                <Typography variant="h6">
                  KINDLY AVOID ANY LOGIN ACTIVITY TILL FURTHER INSTRUCTIONS AS
                  WE ARE STILL IN TESTING PHASE!!!
                </Typography>
              </Box>
            </Box>

            <Box display="flex" justifyContent="center" pb={2} pt={2}>
              <Box className={btnname}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => setOpen8(false)}
                >
                  Close
                </Button>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      </Box>

      <Box>
        <Dialog
          open={open10}
          fullWidth
          // onClose={() => setOpen7(fals)}
          maxWidth="sm"
          // onClose={handleClosee}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <Box style={{ margin: "0 auto", maxWidth: "400px" }}>
              <img src="images/cmg.png" width="100%" />
            </Box>

            <Box display="flex" justifyContent="center" pb={2} pt={2}>
              <Box className={btnname}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => setOpen10(false)}
                >
                  Close
                </Button>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    </>
  );
}
