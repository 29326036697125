import { toast } from "react-toastify";
export const NetworkContextName = "NETWORK";

export const ACTIVE_NETWORK = 97;
export const RPC_URL = "https://data-seed-prebsc-1-s3.binance.org:8545/";
export const stackingContract = "0xd3050a0F98a3B0B6675B046138D3A07fC64DF8E3";
// export const apiKey = `CG-SD4QhZXzEnnijrmAWb96n78c`; //CMC
export const apiKey = `7b5cc1cd-a0f2-4d93-856f-64125a016ac0`; //CMC

//apiKeys
export const marketCurrentAPI = `https://pro-api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=1000&page=1&sparkline=false&price_change_percentage=1h%2C%2024h&x_cg_pro_api_key=${apiKey}`;
export const coinListAPI = `https://api.coingecko.com/api/v3/coins/list`;
export const currentPrice = `https://pro-api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=`;
export const currentPricemyvee = `https://api.coingecko.com/api/v3/simple/price?ids=money-versum&vs_currencies=usd`; // myvee
export const currentPriceINR = `https://api.exchangerate-api.com/v4/latest/USD`; // myvee

export const socketURL = "wss://socket.ebulls.in/socket";
// export const socketURL = "wss://socket-staking.mobiloitte.org/socket";
export const ETH = "0x2170Ed0880ac9A755fd29B2688956BD959F933F8";
export const USDT = "0x55d398326f99059fF775485246999027B3197955";
export const BTC = "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c";
export const EBULLS = "0x5bd8d905e6bd267b8ee0a3a7ac6d431795792b3a"; //testnet
export const MYVEE = "0x4A64cEC042949693fc89Dd1D07D4b2068e7A168D";

export const getNetworkDetails = (chianId) => {
  switch (chianId.toString()) {
    case "42":
      return [
        {
          chainId: "0x2A",
          chainName: "Kovan Test Network",
          nativeCurrency: {
            name: "Kovan Test Network",
            symbol: "ETH",
            decimals: 18,
          },
          rpcUrls: [
            "https://kovan.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
          ],
          blockExplorerUrls: ["https://kovan.etherscan.io"],
        },
      ];
    case "97":
      return [
        {
          chainId: "0x61",
          chainName: "Smart Chain - Testnet",
          nativeCurrency: {
            name: "Smart Chain - Testnet",
            symbol: "BNB",
            decimals: 18,
          },
          rpcUrls: ["https://data-seed-prebsc-1-s3.binance.org:8545/"],
          blockExplorerUrls: ["https://testnet.bscscan.com/"],
        },
      ];
  }
};

export const addNetworkHandler = async (network) => {
  const NetworkDetails = getNetworkDetails(network);
  try {
    await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: NetworkDetails,
    });
  } catch (error) {
    console.log("ERROR", error);
    toast.warn(error.message);
  }
};
// export const TableAddData = async (TotalFundsInUsd) => {
//   let TableAddArr = TotalFundsInUsd.reduce((data) => {
//     if (data.Data) {
//       return {
//         data1: (totalProfit =
//           totalProfit +
//           data.coinList.availableBalance * data.Data.quote.USD.price),
//         data2: (totalProfit =
//           totalProfit +
//           data.coinList.availableBalance * data.Data.quote.USD.price),
//       };
//     }
//   });
//   return TableAddArr;
// };
