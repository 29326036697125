import React, { useContext, useState, useEffect } from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  List,
  Link,
  ListItem,
  makeStyles,
  Button,
  Divider,
  InputBase,
  IconButton,
  FormHelperText,
} from "@material-ui/core";

import { Link as RouterLink } from "react-router-dom";
import Logo from "./../../component/Logo";
import { UserContext } from "src/context/User";
import { useWeb3React } from "@web3-react/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import * as yep from "yup";
import { Formik, Form } from "formik";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Apiconfigs from "src/ApiConfig/ApiConfig";
import { toast } from "react-toastify";
import { FaFacebookF, FaInstagram, FaMediumM } from "react-icons/fa";
import { FiSend, FiTwitter, FiYoutube } from "react-icons/fi";
import { BsMedium, BsFillArrowUpSquareFill } from "react-icons/bs";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";
const useStyles = makeStyles((theme) => ({
  footerSection: {
    position: "relative",
    zIndex: "9",
    [theme.breakpoints.up("sm")]: {
      position: "relative",
      zIndex: "9",
    },
    [theme.breakpoints.up("md")]: {
      position: "relative",
      zIndex: "9",
    },
    "& h1": {
      fontWeight: "600",
      fontSize: "31px",
      [theme.breakpoints.down("md")]: {
        fontSize: "33px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "23px",
      },
    },
    "& h6": {
      fontWeight: "400",
      fontSize: "14px",
      color: "#B7B7B7",
    },
    "& h2": {
      fontWeight: "400",
      fontSize: "16px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "17px",
      },
      "& span": {
        color: "#eab73b",
        backgroundColor: "#eab73b",
        marginLeft: "-11px",
      },
    },
  },
  baseSection: {
    display: "flex",
    justifyContent: "space-between",
    "& p": {
      color: "#fff",
      fontSize: "13px",
    },
    "@media(max-width:565px)": {
      display: "flex",
      flexDirection: "column",
    },
  },
  privacy: {
    display: "flex",
    "@media(max-width:565px)": {
      marginTop: "1.5rem",
    },
  },
  listitem: {
    textDecoration: "none",
    fontFamily: "'Poppins'",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "16px",
    color: "#FFFFFF",
    cursor: "pointer",
    background: "none",
    cursor: "pointer",
    paddingLeft: "0px !important",
    paddingRight: "0px !important",
    "@media(max-width:599px)": {
      fontSize: "14px",
    },
  },
  subpart: {
    backgroundColor: "#24262D",
    padding: "35px 10px 24px 10px",
    position: "relative",
    "& h6": {
      fontSize: "14px",
      color: "#FFFFFF",
      fontWeight: "300",
      lineHeight: "28px",
      width: "100%",
      maxWidth: "448px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
        maxWidth: "100%",
      },
      "@media(max-width:599px)": {
        fontSize: "14px",
        width: "100%",
        maxWidth: "100%",
        marginBottom: "10px",
        lineHeight: "24px",
      },
    },
    "@media(max-width:599px)": {
      padding: "20px 10px 24px 10px",
    },
  },
  listbox: {
    paddingTop: "8px",
    background: "none",
    border: "none",
    margin: "0px",
  },
  logotext: {
    "& h1": {
      fontWeight: "700",
      color: "#EAB73B",
    },
  },
  decoration: {
    textDecoration: "none",
  },
  textFild: {
    position: "relative",
    "& button": {
      position: "absolute",
      top: 0,
      right: 0,
      height: "100%",
      backgroundColor: "#EAB73B",
      minWidth: "140px",
      fontSize: "16px",
      fontWeight: "400",
      color: "#000",
      borderRadius: "10px",
      "&:hover": {
        backgroundColor: "#9d7411",
        color: "#fff",
      },
      [theme.breakpoints.down("xs")]: {
        minWidth: "100px",
      },
    },
  },
  signbox: {
    "& p": {
      fontFamily: "'Poppins'",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "12px",
      lineHeight: "16px",
      color: "#FFFFFF",
    },
  },
  inputfield: {
    "& .inputfield": {
      width: "100%",
      borderRadius: "10px",
      padding: "10px",
    },
    "& input": {
      color: "#949191",
      fontSize: "12px",
    },
  },
  footerContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    "& .MuiIconButton-root": {
      width: "40px",
      height: "40px",
    },

    "& .innerBox": {
      padding: "0px 15px",
      [theme.breakpoints.down("sm")]: {
        padding: "0px 10px",
      },
      "& a": {
        padding: "0px",
        [theme.breakpoints.down("sm")]: {
          fontSize: "11px",
          padding: "0px",
        },
      },
      "& ListItem": {
        "&:hover": {
          color: "#000000",
        },
      },
    },
  },
  bannerBox: {
    display: "flex",
    justifyContent: "flex-end",
    // position: "absolute",
  },
}));

export default function Liquidity({ scrollTo }) {
  const classes = useStyles();
  const user = useContext(UserContext);
  const { account } = useWeb3React();
  const history = useHistory();
  const Tokencheck = window.sessionStorage.getItem("token");

  const [isLoading, setIsLoading] = useState(false);

  const handleFormSubmit = async (values) => {
    setIsLoading(true);

    try {
      const response = await axios({
        method: "POST",
        url: Apiconfigs.subscribeNow,
        data: {
          userEmail: values.email,
          newsLettarStatus: "ACTIVE",
        },
      });
      if (response.data.status === 200) {
        toast.success(response.data.message);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.message);
      setIsLoading(false);
    }
  };

  //new code for scroll top hide
  const [isVisible, setIsVisible] = useState(false);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, []);

  const listenToScroll = () => {
    let heightToHideFrom = 200;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    setHeight(winScroll);

    if (winScroll > heightToHideFrom) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  return (
    <>
      <Box className={classes.footerSection}>
        <Box>
          <Box className={classes.subpart}>
            <Box className={classes.bannerBox}>
              {isVisible && (
                <IconButton
                  className="topScroll"
                  smooth={true}
                  duration={500}
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: "smooth" })
                  }
                >
                  {" "}
                  <BsFillArrowUpSquareFill style={{ color: "#fff" }} />
                </IconButton>
              )}
            </Box>
            <Container>
              <Box className={classes.signbox}>
                <Grid container style={{ color: "white" }} spacing={5}>
                  <Grid item xs={12} sm={6} md={6} lg={5}>
                    <Box className={classes.logotext}>
                      <img
                        src="/images/logo.png"
                        alt=""
                        width="100%"
                        style={{ maxWidth: "187px", margin: "0 auto" }}
                      />
                    </Box>
                    <Box pt={2}>
                      <Typography variant="h6">
                        ebulls is a new, centralised cryptocurrency exchange
                        that is looking to make a huge impact in the industry.
                        The team behind ebulls is highly experienced.
                      </Typography>{" "}
                    </Box>
                  </Grid>
                  <Grid item xs={6} sm={6} md={3} lg={2}>
                    <Typography variant="h2">
                      <span>.</span>
                      &nbsp; Company
                    </Typography>
                    <List className={classes.listbox}>
                      <ListItem
                        className={classes.listitem}
                        onClick={() => {
                          history.push({
                            pathname: "/user-agreement",
                          });
                        }}
                      >
                        User Agreement
                      </ListItem>
                      <ListItem
                        className={classes.listitem}
                        onClick={() => {
                          history.push({
                            pathname: "/privacy",
                          });
                        }}
                      >
                        Privacy Policy
                      </ListItem>
                      <ListItem
                        className={classes.listitem}
                        onClick={() => {
                          history.push({
                            pathname: "/term-condition",
                          });
                        }}
                      >
                        Term & Condition
                      </ListItem>
                      <ListItem
                        className={classes.listitem}
                        onClick={() => {
                          history.push({
                            pathname: "/refund-policy",
                          });
                        }}
                      >
                        Refund Policy
                      </ListItem>
                      <ListItem
                        className={classes.listitem}
                        onClick={() => {
                          history.push({
                            pathname: "/mlm-policy",
                          });
                        }}
                      >
                        AML Policy
                      </ListItem>{" "}
                    </List>{" "}
                  </Grid>
                  <Grid item xs={6} sm={6} md={3} lg={2}>
                    <Typography variant="h2" style={{ color: "#fff" }}>
                      <span>.</span>
                      &nbsp; Coustomer Support
                    </Typography>
                    <List className={classes.listbox}>
                      <ListItem
                        className={classes.listitem}
                        onClick={() => {
                          history.push({
                            pathname: "/about-us",
                          });
                        }}
                      >
                        About Us
                      </ListItem>
                      <ListItem
                        className={classes.listitem}
                        onClick={() => {
                          history.push({
                            pathname: "/Fee",
                          });
                        }}
                      >
                        Fee
                      </ListItem>
                      <ListItem
                        className={classes.listitem}
                        onClick={() => {
                          history.push({
                            pathname: "/delisting-policy",
                          });
                        }}
                      >
                        Delisting Policy
                      </ListItem>
                      <ListItem
                        className={classes.listitem}
                        onClick={() => {
                          history.push({
                            pathname: "/e-commerce",
                          });
                        }}
                      >
                        Referal & Reward
                      </ListItem>{" "}
                    </List>{" "}
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={2}>
                    <Typography variant="h2">
                      <span>.</span>
                      &nbsp; Help and Support
                    </Typography>
                    <List className={classes.listbox}>
                      <ListItem
                        className={classes.listitem}
                        onClick={() => {
                          history.push({
                            pathname: "/faq",
                          });
                        }}
                      >
                        FAQ's
                      </ListItem>
                      <ListItem
                        className={classes.listitem}
                        // onClick={() =>
                        //   window.open(
                        //     `${"https://docs.google.com/forms/d/e/1FAIpQLScTYWKYMOTTJ6gncJPIOfxe9cmlb9LYshljO41BAhI1YLaIww/viewform?pli=1"}`
                        //   )
                        // }
                      >
                        List Your Coin
                      </ListItem>
                      <ListItem
                        className={classes.listitem}
                        onClick={() => {
                          history.push({
                            pathname: "/context-support",
                          });
                        }}
                      >
                        Contact Support
                      </ListItem>{" "}
                    </List>{" "}
                  </Grid>
                </Grid>
                <Box pt={3} pl={1}>
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={6} md={6} lg={5}>
                      <Box>
                        <Formik
                          onSubmit={(values) => handleFormSubmit(values)}
                          initialValues={{
                            email: "",
                          }}
                          initialStatus={{
                            success: false,
                            successMsg: "",
                          }}
                          validationSchema={yep.object().shape({
                            email: yep
                              .string()
                              .required("Please enter valid email address.")
                              .matches(
                                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                "Please enter a valid email address"
                              ),
                          })}
                        >
                          {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            touched,
                            values,
                            setFieldValue,
                          }) => (
                            <Form onSubmit={handleSubmit}>
                              <Typography
                                variant="h2"
                                style={{ color: "#fff" }}
                              >
                                <span>.</span> &nbsp;Subscribe us for Updates
                              </Typography>
                              <Box pt={2} pb={2}>
                                <Box className={classes.textFild}>
                                  <FormControl
                                    fullWidth
                                    className={classes.inputfield}
                                  >
                                    <InputBase
                                      className="inputfield"
                                      id="outlined-basic"
                                      fullWidth
                                      variant="outlined"
                                      placeholder="Your e-mail address"
                                      name="email"
                                      value={values.email}
                                      error={Boolean(
                                        touched.email && errors.email
                                      )}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      disabled={isLoading}
                                      endAdornment={
                                        <InputAdornment position="end">
                                          <Button
                                            type="submit"
                                            disabled={isLoading}
                                          >
                                            Subscribe{" "}
                                            {isLoading && (
                                              <ButtonCircularProgress />
                                            )}
                                          </Button>
                                        </InputAdornment>
                                      }
                                    />

                                    <FormHelperText error>
                                      {touched.email && errors.email}
                                    </FormHelperText>
                                  </FormControl>
                                </Box>
                              </Box>
                              <Typography variant="body1">
                                Subscribe us using your email address to receive
                                news and updates.
                              </Typography>
                            </Form>
                          )}
                        </Formik>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={5}>
                      <Box className={classes.footerContent}>
                        <Box>
                          <Typography variant="h2" style={{ color: "#fff" }}>
                            <span>.</span>
                            &nbsp; Social media
                          </Typography>
                          <Box pt={2}>
                            <IconButton>
                              <Link
                                target="_blank"
                                href="https://www.facebook.com/"
                              >
                                <FaFacebookF
                                  style={{
                                    fontSize: "16px",
                                    color: "#eab73b",
                                  }}
                                />
                              </Link>
                            </IconButton>

                            <IconButton>
                              <Link
                                target="_blank"
                                href="https://www.instagram.com/"
                              >
                                <FaInstagram
                                  style={{
                                    fontSize: "16px",

                                    color: "#eab73b",
                                  }}
                                />
                              </Link>
                            </IconButton>
                            <IconButton>
                              <Link
                                target="_blank"
                                href="https://twitter.com/i/flow/login"
                              >
                                <FiTwitter
                                  style={{
                                    fontSize: "16px",

                                    color: "#eab73b",
                                  }}
                                />
                              </Link>
                            </IconButton>

                            <IconButton>
                              <Link
                                target="_blank"
                                href="https://www.youtube.com/"
                              >
                                <FiYoutube
                                  style={{
                                    fontSize: "16px",

                                    color: "#eab73b",
                                  }}
                                />
                              </Link>
                            </IconButton>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Box pt={3} pb={3}>
                <Divider />
              </Box>
              <Box className={classes.baseSection}>
                <Typography variant="body1">
                  Copyright @ 2022 ebulls. All rights reserved.
                </Typography>
                <Box className={classes.privacy}>
                  <RouterLink to="/market" className={classes.decoration}>
                    <Typography variant="body1">Market</Typography>
                  </RouterLink>
                  &nbsp; &nbsp; &nbsp;
                  <RouterLink to="/exchange" className={classes.decoration}>
                    {" "}
                    <Typography variant="body1">Exchange</Typography>
                  </RouterLink>{" "}
                  &nbsp; &nbsp; &nbsp;
                  <RouterLink to="/staking" className={classes.decoration}>
                    {" "}
                    <Typography variant="body1">Staking</Typography>
                  </RouterLink>{" "}
                  {Tokencheck ? (
                    <>
                      &nbsp; &nbsp; &nbsp;
                      <RouterLink
                        to="/dashboard"
                        className={classes.decoration}
                      >
                        <Typography variant="body1">Wallet</Typography>
                      </RouterLink>
                    </>
                  ) : (
                    <>
                      &nbsp; &nbsp; &nbsp;
                      <RouterLink to="/login" className={classes.decoration}>
                        <Typography variant="body1">Wallet</Typography>
                      </RouterLink>
                    </>
                  )}
                </Box>
              </Box>
            </Container>
          </Box>
        </Box>
      </Box>
    </>
  );
}
